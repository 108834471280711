/* eslint-disable react/no-danger */
// import React, { useContext } from 'react';
import React from 'react';
import parse from 'html-react-parser';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { includes, isEmpty } from 'lodash';
// import ReactPlayer from 'react-player';
import { Stream } from '@cloudflare/stream-react';
import ApartmentsContext from '../utils/context';
import { getTranslationByKey } from '../helpers/translation-helper';
import 'react-lazy-load-image-component/src/effects/blur.css';
import RoomsAndApartmentsList from '../services/RoomsAndApartmentsList';
import ResidencesList from '../services/ResidencesList';

export default function RoomsList({ data, id, dir, settings }) {
  
  const { title, subtitle, text, topBackgroundImage, bottomBackgroundImage, videoUrl } = data;
  if (isEmpty(data)) return null;
  // let vid = videoUrl;
  // if (videoUrl) {
  //   vid = videoUrl.replace('https://www.youtube.com/embed/', '');
  // }
  
  const { translations, cssClass } = React.useContext(ApartmentsContext);
  const lang = dir === 'rtl' ? 'ar' : 'en';
  // const isBrowser = typeof window !== 'undefined';
  const pathdata = data.currPage === `/${lang}/residences/` ? 'hidden' : '';

  // const isResidence = () => {
  //   if (isBrowser) {
  //     return window.location.pathname === `/${lang}/residences/`;
  //   }
  //   return false;
  // };

  const roomList = includes(data.currPage, 'residences')
    ? new ResidencesList()
    : new RoomsAndApartmentsList();
  const list = roomList.get(lang);

  const { reservationLink } = settings;

  return (
    <section id={id} className="room-list-page relative overflow-hidden room_listsection">
      {topBackgroundImage ? (
        <div className="bg-top inner-page-banner absolute inset-x-0 z-1">
          <LazyLoadImage
            src={topBackgroundImage.url}
            width={1920}
            height={1080}
            alt=""
            placeholderSrc={`${topBackgroundImage.url}?width=50&quality=50`}
            effect="blur"
          />
        </div>
      ) : null}

      {isEmpty(videoUrl) || (
        <div className="video-banner absolute top-0 left-0 right-0">
          <div className="video relative z-1 overflow-hidden h-0">
            <Stream
              controls={false}
              src={videoUrl}
              height="100%"
              width="100%"
              autoplay
              loop
              responsive
            />
            {/* <ReactPlayer
              url={`${videoUrl}?autoplay=1&mute=1&playsinline&rel=0&modestbranding=1&autohide=1&showinfo=0&controls=0&loop=1&playlist=${vid}`}
              playing
              controls={false}
              loop
              muted
              playsinline
              width="100%"
              className="absolute z-1 w-full h-full rct_player"
            /> */}
          </div>
        </div>
      )}

      <div className="container mx-auto relative z-3 md:py-20 py-5">
        <div className="grid grid-cols-12 pt-20">
          <div className="col-span-12 md:col-span-10 md:ltr:ml-20 md:ltr:pl-5 md:rtl:mr-20 md:rtl:pr-5 pt-20 pb-5">
            <h3 className="pt-20 ltr:font-din rtl:font-tahoma ltr:md:text-18-40 rtl:md:text-16-38 subheadline-text font-semibold ltr:tracking-space12 uppercase mb-5">
              {title}
            </h3>
            <h1 className="ltr:font-georgia rtl:font-DroidArabicNaskh text-24-35 md:text-38-52 mb-6">
              {subtitle}
            </h1>
            <div className="text-13-22 ltr:md:text-18-32 rtl:md:text-16-30 intro-text">
              {text ? parse(text) : null}
            </div>
          </div>
        </div>
      </div>

      <div className={`container mx-auto relative z-3 ${cssClass}`}>
        <div className="grid grid-cols-2 mb-7 gap-8">
          {isEmpty(list) ||
            list.map((room, index) => (
              <div className="col-span-2 sm:col-span-1" key={index}>
                <div className="room-card">
                  {room.featuredImage ? (
                    <div className="room-image">
                      <LazyLoadImage
                        src={`${room.featuredImage.url}?width=570&height=270&mode=crop`}
                        width={570}
                        height={270}
                        alt=""
                        placeholderSrc={`${room.featuredImage.url}?width=57&height=27&mode=crop&quality=50`}
                        effect="blur"
                        className="h-full"
                      />
                    </div>
                  ) : null}
                  <div className="card-card room_card_description bg-dark-blue">
                    <div
                      className="roomlist-card-content px-8 pb-15 pt-6  rtl:text-14-20 ltr:text-15-22"
                      dir={dir}
                    >
                      <h4 className="text-gold rtl:text-15-22 ltr:text-18-26 ltr:font-georgia rtl:font-DroidArabicNaskh">
                        {room.title}
                      </h4>
                      <ul className="list-none p-0">
                        {isEmpty(room.description) || (
                          <div dangerouslySetInnerHTML={{ __html: room.description }} />
                        )}
                      </ul>
                      <div className="cta-wrapper pt-3 md:pb-12 pb-8 room_cta_link">
                        <a
                          href={room.url.replace(`/apartments/`, `/${lang}/`)}
                          className="text-white uppercase border-1 border-solid border-gold inline-block hover:bg-gold font-semibold"
                        >
                          { room.cssClass === "room" ? getTranslationByKey(translations, 'Room Details') : <> { room.cssClass === "apartment" ? getTranslationByKey(translations, 'Apartment Details') : getTranslationByKey(translations, 'Residence Details')} </> }
                        </a>
                        <a
                          href={room?.cTA ? room?.cTA?.url.replace(`/apartments/`, `/${lang}/`) : reservationLink.url}
                          target={`${room?.cTA ? '' : "_blank"}`}
                          rel="noreferrer"
                          className={`ltr:ml-4 rtl:mr-4 font-semibold text-white underline hover:no-underline whitespace-nowrap reserve_nowbutton ${pathdata}`}
                        >
                          {getTranslationByKey(translations, `${room?.cTA ? 'Contact Us' : 'Reserve Now'}`)}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>

      {bottomBackgroundImage ? (
        <div className="bg-bottom mb-48 relative z-2 buttom_background">
          <LazyLoadImage
            src={bottomBackgroundImage.url}
            width={1920}
            height="auto"
            alt={bottomBackgroundImage.alt}
            placeholderSrc={`${bottomBackgroundImage.url}?width=50&quality=50`}
            effect="blur"
          />
        </div>
      ) : null}
    </section>
  );
}
